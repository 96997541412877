import { Api, BASE_URL, BOTS_BASE_URL } from '@/api/index';
import { WeddingEventSettings } from '@/types/wedding';
import axios, { AxiosRequestConfig } from 'axios';
import { CreateWeddingEvent, WeddingEvent } from 'ignite360-core';

export class WeddingApi {
  static async loadBotWeddingEvents(botId: string): Promise<WeddingEvent[]> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events`,
    });
    const result = await axios.request<WeddingEvent[]>(config);
    return result.data;
  }

  // create a new campaign
  static async createBotWeddingEvent(
    botId: string,
    event: CreateWeddingEvent,
  ): Promise<WeddingEvent> {
    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events`,
      data: event,
    });
    const result = await axios.request<WeddingEvent>(config);
    return result.data;
  }

  static async getPublicWeddingEvent(eventId: string): Promise<WeddingEvent> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BASE_URL}/public/wedding/events/${eventId}`,
    });
    const result = await axios.request<WeddingEvent>(config);
    return result.data;
  }

  static async getBotWeddingEvent(botId: string, eventId: string): Promise<WeddingEvent> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events/${eventId}`,
    });
    const result = await axios.request<WeddingEvent>(config);
    return result.data;
  }

  static async uploadImages(botId: string, eventId: string, formData: FormData) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events/${eventId}/images`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.request(Api.finalizeConfig(config));
  }

  static async uploadPublicImages(botId: string, eventId: string, formData: FormData) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/public/wedding/events/${eventId}/images`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.request(Api.finalizeConfig(config));
  }

  static async deleteBotWeddingEvent(botId: string, eventId: string) {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events/${eventId}`,
    });
    return axios.request(config);
  }

  static async updateBotWeddingEvent(botId: string, eventId: string, event: WeddingEventSettings) {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events/${eventId}`,
      data: event,
    });
    return axios.request(config);
  }

  static async deleteBotWeddingEventImage(botId: string, eventId: string, imageId: string) {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events/${eventId}/images/${imageId}`,
    });
    return axios.request(config);
  }

  static async deletePublicBotWeddingEventImage(botId: string, eventId: string, imageId: string) {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/public/wedding/events/${eventId}/images/${imageId}`,
    });
    return axios.request(config);
  }

  static async updateBotWeddingEventImagesOrder(
    botId: string,
    eventId: string,
    updatedOrder: { id: string; order: number }[],
  ) {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events/${eventId}/images/order`,
      data: { updatedOrder },
    });
    return axios.request(config);
  }

  // Add this method to the WlcmHomeApi class

  static async updateImageHeroStatus(
    botId: string,
    eventId: string,
    imageId: string,
    heroImage: boolean,
  ) {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events/${eventId}/images/${imageId}/hero`,
      data: { heroImage },
    });
    return axios.request(config);
  }

  static async uploadAgentImage(botId: string, eventId: string, formData: FormData) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events/${eventId}/agent-image`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.request(Api.finalizeConfig(config));
  }

  static async deleteAgentImage(botId: string, eventId: string) {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events/${eventId}/agent-image`,
    });
    return axios.request(config);
  }

  static async updateImageState(botId: string, eventId: string, imageId: string, state: string) {
    console.log('state', state);
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${botId}/wedding/events/${eventId}/images/${imageId}/state`,
      data: { state },
    });
    return axios.request(config);
  }
}
